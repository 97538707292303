/** @jsx jsx */
import {jsx, css} from '@emotion/react'
import {colors} from '../../../constants'

const Footer = () => {
    return (
        <footer
            css={css`
                padding: 2rem 1rem;
                background: ${colors.whisper};
                text-align: center;
                color: #1f2a47;
            `}
        >
            Startpage.com © {new Date().getFullYear()}
        </footer>
    )
}

export default Footer
