import React, {useState} from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Layout from '../components/layout'
import {useOnce} from '../util/customHooks'
import {EVENTS, logDplWithExtensionParams} from '../util/dpl'
import {setExtensionParamsToLocalStorage} from '../util/extensionMetadata'
import {isUnsupported} from '../util/userAgent'

import Article from '../components/templates/article-ad-1/Article'
import Header from '../components/templates/article-ad-1/Header'
import Footer from '../components/templates/article-ad-1/Footer'
import IndexUnsupported from '../components/unsupported/index-unsupported'

const experiment = `article-ad-1`

const Article1 = ({languageCode = `en`}) => {
    const site = useStaticQuery(graphql`
        query Article1Query {
            site {
                siteMetadata {
                    ...ArticleAd
                }
            }
        }
    `)
    const content = site.site.siteMetadata.articleAd[languageCode]

    const {headerCtaText} = content

    const [showUnsupportedPage, setShowUnsupportedPage] = useState(false)

    useOnce(() => {
        if (isUnsupported()) {
            setShowUnsupportedPage(true)
        } else {
            setExtensionParamsToLocalStorage(experiment).then(() => {
                logDplWithExtensionParams(EVENTS.StartpagePageLoad, {
                    experiment,
                })
            })
        }
    })

    return (
        <Layout>
            <Header experiment={experiment} languageCode={languageCode} ctaText={headerCtaText} />
            {showUnsupportedPage ? (
                <IndexUnsupported experiment="unsupported" />
            ) : (
                <Article content={content} languageCode={languageCode} experiment={experiment} />
            )}
            <Footer />
        </Layout>
    )
}

export default Article1
