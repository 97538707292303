import React, {useState, useEffect} from 'react'
import {css} from '@emotion/react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {convertDatoCmsImgPathsToLocal} from '../util/datoCmsDataFactory'

const StyledImage = styled.img(
    ({overrideStyles}) => css`
        transition: 'all 0.4s ease';
        ${overrideStyles}
    `,
)

const Image = ({src, alt, overrideStyles}) => {
    const [imgSrc, setImgSrc] = useState('')

    useEffect(() => {
        const img = document.createElement('img')
        img.src = src
        img.onload = () => {
            setImgSrc(src)
        }
    })

    return (
        <StyledImage
            style={{filter: imgSrc ? '' : 'blur(3px)', transition: 'filter 0.5s ease'}}
            src={convertDatoCmsImgPathsToLocal(src)}
            alt={alt}
            overrideStyles={overrideStyles}
        />
    )
}

Image.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
}

Image.defaultProps = {
    src: '',
    alt: '',
}

export default Image
