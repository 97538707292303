import {css} from '@emotion/react'
import {useState} from 'react'
import {colors} from '../../../constants'
import {useOnce} from '../../../util/customHooks'
import {convertDatoCmsImgPathsToLocal, createMarkup} from '../../../util/datoCmsDataFactory'
import {EVENTS, logDplWithExtensionParams} from '../../../util/dpl'
import {chromeProtectionExtensionLink, firefoxProtectionExtensionLink} from '../../../util/extensionStoreLinks'
import {isChrome} from '../../../util/userAgent'
import Image from '../../Image'

const ExtensionStoreWidget = ({data, ctaType = `cta-1`, experiment = `article-ad-1`, languageCode = `en`}) => {
    const {
        browserLogos,
        bulletItemIcon,
        bulletItemText,
        ctaText,
        downloads,
        starRating: {url: starRatingImage},
    } = data

    const [chromeLogoData, firefoxLogoData] = browserLogos

    const chromeLogo = chromeLogoData.url
    const firefoxLogo = firefoxLogoData.url

    const [browserLogoImage, setBrowserLogoImage] = useState(firefoxLogo)
    const [browserIsChrome, setBrowserIsChrome] = useState(false)
    const [extensionLink, setExtensionLink] = useState(firefoxProtectionExtensionLink(languageCode))

    useOnce(() => {
        if (isChrome()) {
            setBrowserLogoImage(chromeLogo)
            setBrowserIsChrome(true)
            setExtensionLink(chromeProtectionExtensionLink(languageCode))
        }
    })

    const onCtaClick = (e) => {
        e.preventDefault()
        logDplWithExtensionParams(EVENTS.StartpageClick, {
            type: ctaType,
            experiment,
        }).then(() => {
            window.location.href = extensionLink
        })
    }

    return (
        <div
            className="extension-store-widget"
            css={css`
                margin: 4em 0;
            `}
        >
            <div
                className="stats"
                css={css`
                    display: flex;
                    align-items: center;
                    margin-bottom: 12px;
                    h3 {
                        margin: 0 12px;
                        p {
                            margin: 0;
                        }
                    }
                `}
            >
                <Image src={convertDatoCmsImgPathsToLocal(browserLogoImage)} alt="browser-logo" />
                <h3 dangerouslySetInnerHTML={createMarkup(downloads)} />
                <Image
                    className="star-rating"
                    src={convertDatoCmsImgPathsToLocal(starRatingImage)}
                    alt="star-rating"
                    overrideStyles={css`
                        border-left: 2px solid ${colors.whisperDark};
                        padding-left: 12px;
                    `}
                />
            </div>

            <div
                className="bullets"
                css={css`
                    margin: 20px 0 24px;
                `}
            >
                {bulletItemText.map(({bulletText}) => {
                    return (
                        <div
                            className="bullet"
                            css={css`
                                display: flex;
                                align-items: center;
                                margin-bottom: 12px;
                                img {
                                    margin-right: 12px;
                                }
                            `}
                            key={bulletText}
                        >
                            <img src={convertDatoCmsImgPathsToLocal(bulletItemIcon.url)} alt="bullet-icon" />
                            <div
                                dangerouslySetInnerHTML={createMarkup(
                                    bulletText.replace('{}', browserIsChrome ? `Google Chrome` : `Firefox`),
                                )}
                            />
                        </div>
                    )
                })}
            </div>

            <a
                className="cta"
                dangerouslySetInnerHTML={createMarkup(ctaText)}
                href={extensionLink}
                target="_blank"
                rel="noopener nofollow noreferrer"
                aria-label="extension-install-link"
                onClick={onCtaClick}
                css={css`
                    display: inline-block;
                    border-radius: 4px;
                    color: ${colors.whisperLight};
                    font-weight: 600;
                    background: ${colors.brandBlue};
                    padding: 1rem 2rem;
                    &:hover {
                        background: ${colors.brandBlueLight};
                    }
                `}
            />
        </div>
    )
}

export default ExtensionStoreWidget
