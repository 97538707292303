import {useState} from 'react'
import {css} from '@emotion/react'
import StartpageLogo from '../../../images/startpage-logo-new.svg'
import {colors} from '../../../constants'
import {firefoxProtectionExtensionLink, chromeProtectionExtensionLink} from '../../../util/extensionStoreLinks'
import {logDplWithExtensionParams, EVENTS} from '../../../util/dpl'
import {useOnce} from '../../../util/customHooks'
import {isChrome} from '../../../util/userAgent'
import {createMarkup} from '../../../util/datoCmsDataFactory'

const Header = ({experiment, ctaText, languageCode}) => {
    const [extensionLink, setExtensionLink] = useState(firefoxProtectionExtensionLink(languageCode))

    useOnce(() => {
        if (isChrome()) {
            setExtensionLink(chromeProtectionExtensionLink(languageCode))
        }
    })

    const onCtaClick = (e) => {
        e.preventDefault()
        logDplWithExtensionParams(EVENTS.StartpageClick, {
            type: 'cta-1',
            experiment,
        }).then(() => {
            window.location.href = extensionLink
        })
    }

    return (
        <header
            css={css`
                background: ${colors.whisper};
                padding: 1.5rem 1rem;
            `}
        >
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    max-width: 800px;
                    margin: auto;
                    @media (max-width: 600px) {
                        flex-direction: column;
                    }
                `}
            >
                <a href="https://www.startpage.com">
                    <StartpageLogo
                        css={css`
                            width: 115px;
                            &:hover {
                                cursor: pointer;
                            }
                            @media (max-width: 600px) {
                                margin: 0 0 18px;
                            }
                        `}
                    />
                </a>
                <a
                    className="cta"
                    dangerouslySetInnerHTML={createMarkup(ctaText)}
                    href={extensionLink}
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                    aria-label="extension-install-link"
                    onClick={onCtaClick}
                    css={css`
                        display: inline-block;
                        border-radius: 4px;
                        color: ${colors.whisperLight};
                        font-weight: 600;
                        background: ${colors.brandBlue};
                        padding: 1rem 2rem;
                        color: ${colors.whisperLight};
                        &:hover {
                            background: ${colors.brandBlueLight};
                        }
                    `}
                />
            </div>
        </header>
    )
}

export default Header
