import React, {useState} from 'react'
import {css} from '@emotion/react'
import {convertDatoCmsImgPathsToLocal, createMarkup} from '../../../util/datoCmsDataFactory'
import ExtensionStoreWidget from './ExtensionStoreWidget'
import {isChrome} from '../../../util/userAgent'
import {useOnce} from '../../../util/customHooks'
import Image from '../../Image'
import SEO from '../../seo'

const Article = ({content, languageCode = `en`, experiment = `article-ad-1`}) => {
    const {
        title,
        heroImages,
        image2,
        image3,
        textBlock1,
        textBlock2,
        textBlock3,
        reviews,
        extensionStoreWidget1,
        extensionStoreWidget2,
    } = content

    const [chromeHeroImage, firefoxHeroImage] = heroImages
    const [extensionStoreWidget1Data] = extensionStoreWidget1
    const [extensionStoreWidget2Data] = extensionStoreWidget2

    const [browserHeroImage, setBrowserHeroImage] = useState(firefoxHeroImage.url)
    const [formattedTitle, setFormattedTitle] = useState(title.replace(`{}`, `Firefox`))
    const [showPage, setShowPage] = useState(false)
    const [browserIsChrome, setBrowserIsChrome] = useState(false)

    useOnce(() => {
        if (isChrome()) {
            setBrowserIsChrome(true)
            setBrowserHeroImage(chromeHeroImage.url)
            setFormattedTitle(title.replace(`{}`, `Chrome`))
        }
        window.addEventListener('load', () => {
            setShowPage(true)
        })
        setTimeout(() => {
            setShowPage(true)
        }, 500)
    })

    const mainlineImageOverrideStyles = css`
        width: 100%;
        background: #f8f8f8;
    `

    return (
        <>
            <SEO
                title="Install Startpage Privacy Protection Extension"
                description="Take control of who can see your personal data with Startpage Privacy Protection. We make privacy and personal data protection easy!"
                ogImagePath="https://www.startpage.com/sp/cdn/images/startpage-shield.png"
            />
            <div
                className="article"
                css={css`
                    opacity: ${showPage ? '1' : '0'};
                    transition: opacity 0.2s ease;
                    padding: 4rem 1rem;
                    max-width: 800px;
                    margin: 0 auto;
                    .section-image {
                        width: 100%;
                    }
                    h1 {
                        font-size: 40px;
                        font-size: min(max(26px, 4vw), 40px);
                        margin: 0 0 24px;
                    }
                    h2 {
                        font-size: 32px;
                        font-size: min(max(20px, 4vw), 32px);
                        margin: 24px 0;
                    }
                    p {
                        margin: 24px 0;
                    }
                    h1,
                    strong,
                    b {
                        font-weight: 600;
                    }
                `}
            >
                <section className="section1">
                    <h1>{formattedTitle}</h1>
                    <Image
                        className="section-image"
                        src={convertDatoCmsImgPathsToLocal(browserHeroImage)}
                        alt="hero"
                        overrideStyles={mainlineImageOverrideStyles}
                    />
                    <div dangerouslySetInnerHTML={createMarkup(textBlock1)} />
                </section>

                <section className="section2">
                    <Image
                        className="section-image"
                        src={convertDatoCmsImgPathsToLocal(image2.url)}
                        alt="section-2"
                        overrideStyles={mainlineImageOverrideStyles}
                    />
                    <div
                        dangerouslySetInnerHTML={createMarkup(
                            textBlock2.replace(/{}/gi, browserIsChrome ? 'Chrome' : 'Firefox'),
                        )}
                    />
                </section>

                <ExtensionStoreWidget
                    data={extensionStoreWidget1Data}
                    ctaType="cta-2"
                    languageCode={languageCode}
                    experiment={experiment}
                />

                <section className="section3">
                    <Image
                        className="section-image"
                        src={convertDatoCmsImgPathsToLocal(image3.url)}
                        alt="section-3"
                        overrideStyles={mainlineImageOverrideStyles}
                    />
                    <div
                        dangerouslySetInnerHTML={createMarkup(
                            textBlock3
                                .replace('{store_rating}', browserIsChrome ? '4.5/5' : '4.7/5')
                                .replace(
                                    '{}',
                                    browserIsChrome ? 'Google Chrome Webstore' : 'Firefox Browser Add-ons marketplace',
                                ),
                        )}
                    />
                </section>

                <ExtensionStoreWidget
                    data={extensionStoreWidget2Data}
                    ctaType="cta-3"
                    languageCode={languageCode}
                    experiment={experiment}
                />
                {browserIsChrome && (
                    <section className="reviews">
                        {reviews.map(({reviewText, starRating: {url: starRatingImage}}) => {
                            return (
                                <div
                                    className="review"
                                    key={reviewText}
                                    css={css`
                                        background: #f8f8f8;
                                        border-radius: 4px;
                                        padding: 20px 40px;
                                        margin-bottom: 20px;
                                        p {
                                            margin-bottom: 0;
                                        }
                                    `}
                                >
                                    <img src={convertDatoCmsImgPathsToLocal(starRatingImage)} alt="rating" />
                                    <div dangerouslySetInnerHTML={createMarkup(reviewText)} />
                                </div>
                            )
                        })}
                    </section>
                )}
            </div>
        </>
    )
}

export default Article
