import {useState, useEffect} from 'react'

export const useOnce = (callback) => {
    const [once, setOnce] = useState(false)

    useEffect(() => {
        if (!once) {
            callback()
            setOnce(true)
        }
    }, [once, callback])
}
